<template>
  <div>
    <picker
      document-types="credit-statuses"
      :label="$t('t.CreditStatuses')"
      :selected-id.sync="computedCreditStatus"
      :clearable="false"
      :dense="dense"
      :rules="required"
      :disabled="disabled"
      :attach="false"
    />
    <number-field
      :label="$t('t.CreditUsagePercentSup')"
      nullable
      :number.sync="computedPercentMin"
      :rules="rules"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
    />
    <number-field
      :label="$t('t.CreditUsagePercentInf')"
      nullable
      :number.sync="computedPercentMax"
      :rules="rules"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
    />
    <div>
      <toggle-consolidated
        class="mt-5"
        v-if="computedPercentMin != null || computedPercentMax != null"
        consolidable
        :consolidated.sync="computedConsolidated"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    ToggleConsolidated: () => import('@/components/toggle-consolidated'),
    Picker: () => import('@/components/picker'),
    NumberField: () => import('@/components/number-field')
  },
  computed: {
    computedCreditStatus: {
      get () { return this.dataCreditStatus },
      set (v) {
        this.dataCreditStatus = v
        this.dataDocumentEmit()
      }
    },
    computedPercentMin: {
      get () { return this.dataPercentMin },
      set (v) {
        this.dataPercentMin = v
        this.dataDocumentEmit()
      }
    },
    computedPercentMax: {
      get () { return this.dataPercentMax },
      set (v) {
        this.dataPercentMax = v
        this.dataDocumentEmit()
      }
    },
    computedConsolidated: {
      get () { return this.dataConsolidated },
      set (v) {
        this.dataConsolidated = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      rules: [v => v >= 0 || this.$t('t.MustBeGreaterOrEqualTo', { value: 0 })],
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataCreditStatus: 'ok',
      dataPercentMin: null,
      dataPercentMax: null,
      dataConsolidated: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'update-credit-status', creditStatus: this.dataCreditStatus, creditUsagePercentMin: this.dataPercentMin, creditUsagePercentMax: this.dataPercentMax, consolidated: this.dataConsolidated }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataCreditStatus = v?.creditStatus ?? 'ok'
        this.dataPercentMin = v?.creditUsagePercentMin ?? null
        this.dataPercentMax = v?.creditUsagePercentMax ?? null
        this.dataConsolidated = v?.consolidated ?? false
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
